import { AssetDoc } from '@livekatsomo/models';
import { getContrast, hashInt, hslToRgb } from '@livekatsomo/shared/utils';
import { AssetAvatar } from '@livekatsomo/web/ui-components/asset-avatar';
import initials from 'initials';

/**
 * Props for the LiveAvatar component.
 */
export interface LiveAvatarProps {
  /**
   * The name of the avatar.
   */
  name?: string;
  /**
   * The asset for the avatar.
   */
  asset?: Pick<AssetDoc, 'downloadUrls' | 'originalUrl' | 'purpose' | 'type'>;
  /**
   * The URL for the avatar.
   */
  url?: string | null;
  /**
   * The children of the avatar.
   */
  children?: React.ReactNode;
}

/**
 * Renders a Live Avatar component with the given name, url, asset and children.
 *
 * @param {LiveAvatarProps} props - The props for the Live Avatar component.
 * @param props.name - The name of the user.
 * @param props.url - The URL of the user's avatar image.
 * @param props.asset - The asset of the user's avatar image.
 * @param {ReactNode} props.children - The children to be rendered inside the Live Avatar component.
 * @returns The Live Avatar component.
 */
export function LiveAvatar({ name, url, asset, children }: LiveAvatarProps) {
  const hue = name && hashInt(name, 360);
  const bgColor = hue && hslToRgb(hue / 360, 1, 0.8);
  const abbr = name && initials(name);
  const contrast = bgColor && getContrast(bgColor);

  return (
    <AssetAvatar
      sx={[
        {
          color:
            contrast === 'light'
              ? (theme) => theme.palette.grey[100]
              : (theme) => theme.palette.grey[900],
          textTransform: 'uppercase',
        },
        !!bgColor && {
          bgcolor: bgColor,
        },
      ]}
      alt={name}
      url={url}
      asset={asset}
    >
      {children ? children : abbr}
    </AssetAvatar>
  );
}

export default LiveAvatar;
