import { Event } from '@livekatsomo/models';

/**
 * Determines if an event is currently live based on its start and end dates.
 * @param event - The event to check.
 * @param now - The current date and time. Defaults to the current system time.
 * @returns True if the event is currently live, false otherwise.
 */
export function isLiveRightNow(event: Event, now = new Date()): boolean {
  const { startDate, endDate } = event;
  return startDate <= now && endDate >= now;
}
