import { zodResolver } from '@hookform/resolvers/zod';
import { SigninProcessStatus } from '@livekatsomo/types';
import { NextLinkComposed } from '@livekatsomo/web/ui-components/link';
import { TextField } from '@livekatsomo/web/ui-components/react-hook-form-components';
import EmailIcon from '@mui/icons-material/Email';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

export interface ForgotPasswordProps {
  email?: string;
  errorMessage?: ReactNode;
  processing?: SigninProcessStatus;
  onSwitchToRegister?: (email: string) => void;
  onSendPasswordResetEmail: (email: string) => Promise<void>;
  onClose?: () => void;
}

const formSchema = z.object({
  email: z.string().email(),
});

/**
 * A form component for resetting password. It takes an email address as input and sends a password reset email to the provided email address.
 * @param email - The email address to pre-fill in the email input field.
 * @param errorMessage - An optional error message to display.
 * @param processing - An optional status to indicate if the form is processing.
 * @param onSwitchToRegister - An optional callback function to switch to the registration form.
 * @param onSendPasswordResetEmail - A callback function to send a password reset email.
 * @param onClose - An optional callback function to close the form.
 */
export function ForgotPasswordForm({
  email: providedEmail = '',
  errorMessage,
  onSendPasswordResetEmail,
  onSwitchToRegister,
  onClose,
}: ForgotPasswordProps) {
  const { t } = useTranslation();
  const router = useRouter();
  const initialValues = { email: providedEmail };

  const submit = async (values: typeof initialValues) => {
    await onSendPasswordResetEmail(values.email);
  };

  const methods = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const {
    formState: { isDirty, isSubmitting, isValid },
    handleSubmit,
    watch,
  } = methods;

  const email = watch('email');

  return (
    <FormProvider {...methods}>
      <Box
        component={'form'}
        onSubmit={handleSubmit(submit)}
        data-test="forgot-password-form"
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}
      >
        <FormControl variant="standard" fullWidth>
          <TextField
            name="email"
            type="email"
            required
            fullWidth
            label={t('Email')}
            placeholder={t('Your email') || 'Yout email'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Button
          type="submit"
          data-test="reset-password-button"
          variant="contained"
          fullWidth
          disabled={!isDirty || !isValid || isSubmitting}
        >
          {t('Reset password')}
        </Button>
        {onClose ? (
          <Button onClick={onClose} variant="outlined" fullWidth type="button">
            {t('Cancel')}
          </Button>
        ) : null}
        {errorMessage ? (
          <Typography color="error">{errorMessage}</Typography>
        ) : (
          ''
        )}
        <p>
          {t('Don&apos;t have account yet?')}{' '}
          {onSwitchToRegister ? (
            <Button onClick={() => onSwitchToRegister(email)}>
              {t('Sign Up')}
            </Button>
          ) : (
            <Button
              component={NextLinkComposed}
              to={
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                { pathname: '/register', query: { ...router.query } } as any
              }
            >
              {t('Sign up')}
            </Button>
          )}
        </p>
      </Box>
    </FormProvider>
  );
}
