import {
  EnvironmentContextModel,
  EnvironmentContext,
} from '@livekatsomo/web/contexts';
import { useContext } from 'react';

/**
 * Returns the environment context.
 * @returns The environment context.
 * @throws An error if used outside of a `EnvironmentProvider`.
 */
export function useEnvironment(): EnvironmentContextModel {
  const context = useContext(EnvironmentContext);
  if (context === undefined) {
    throw new Error('useEnvironment must be used within EnvironmentProvider');
  }

  return context;
}
