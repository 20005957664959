import { useAuthentication } from '@livekatsomo/web/data-hooks';
import {
  AuthenticationErrorViewer,
  UserAlreadyRegisteredWithOtherMethodError,
} from '@livekatsomo/web/features/authentication-error-viewer';
import { ForgotPasswordForm } from '@livekatsomo/web/features/forgot-password';
import {
  RegisterForm,
  RegisterWithProviders,
  SignInForm,
  SignInWithProviders,
} from '@livekatsomo/web/ui-components/authentication';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { ReauthenticateFeature } from './reauthenticate/ReauthenticateFeature';
import { ContinueWithNickForm } from './ContinueWithNickForm';
import { UserAlreadyRegisteredWithOtherProviderError } from '@livekatsomo/custom-errors';

/**
 * Renders a modal for user authentication, including registration, sign in, forgot password, and anonymous sign in.
 * Uses various UI components and data hooks from the @livekatsomo/web package.
 * @returns JSX.Element
 */
export function AuthenticationModalFeature() {
  const { t } = useTranslation();
  const {
    authenticationSuccess,
    error,
    processing,
    authenticationModalVisible,
    createUser,
    signInWithEmailAndPassword,
    googleSignIn,
    facebookSignIn,
    twitterSignIn,
    sendPasswordResetEmail,
    showModal,
    signInAnonymously,
    setError,
    setProcessing,
    onSuccesfullAuthentication,
  } = useAuthentication();

  const [email, setEmail] = useState<string>();

  // TODO: When is this used?
  useEffect(() => {
    if (
      authenticationSuccess &&
      !error &&
      authenticationModalVisible !== 'reauthenticate'
    ) {
      if (onSuccesfullAuthentication) {
        onSuccesfullAuthentication();
      }
      showModal(null, null);
    }
  }, [
    authenticationModalVisible,
    authenticationSuccess,
    error,
    onSuccesfullAuthentication,
    showModal,
  ]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSendPasswordResetEmail = async (email: string) => {
    await sendPasswordResetEmail(email).then((user) => {
      enqueueSnackbar('Password reset email sent, please check your email', {
        variant: 'success',
      });
    });
  };

  function closeModal() {
    return showModal(null, null);
  }

  const handleCreateUser = async (
    email: string,
    password: string,
    name: string,
  ) => {
    try {
      const user = await createUser(email, password, name);
      enqueueSnackbar(t('Signed up successfully'), {
        variant: 'success',
      });
      return user;
    } catch (error) {
      setError(error as Error);
      console.error(error);
      return null;
    }
  };

  const register = (
    <>
      <DialogTitle>{t('Register with email and password')}</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: 2,
        }}
      >
        <RegisterForm
          email={email}
          onClose={closeModal}
          onCreateUser={handleCreateUser}
          onSwitchToLogin={(email) => {
            showModal('signIn');
            setEmail(email);
          }}
          processing={processing}
          errorMessage={
            error ? (
              <AuthenticationErrorViewer
                error={error}
                email={email}
                onSwitchToLogin={(email) => {
                  showModal('signIn');
                  setEmail(email);
                }}
              />
            ) : null
          }
        />
        <Divider>
          <Chip color="secondary" label={t('OR')} />
        </Divider>
        <RegisterWithProviders
          processing={processing}
          onFacebookSignIn={facebookSignIn}
          onGoogleSignIn={googleSignIn}
          onTwitterSignIn={twitterSignIn}
        />
      </DialogContent>
    </>
  );

  const handleSignInWithEmailAndPassword = async (
    email: string,
    password: string,
  ): Promise<void> => {
    try {
      setProcessing('password');
      setError(null);
      await signInWithEmailAndPassword(email, password);
      enqueueSnackbar(t('Signed in successfully'), {
        variant: 'success',
      });
      if (onSuccesfullAuthentication) {
        onSuccesfullAuthentication();
      }
      showModal(null, null);
    } catch (error) {
      enqueueSnackbar(t('Sign in failed'), {
        variant: 'error',
      });
      setError(error as Error);
    } finally {
      setProcessing(null);
    }
  };
  const handleFacebookSignIn = async () => {
    try {
      setProcessing('facebook.com');
      setError(null);
      await facebookSignIn();
      enqueueSnackbar(t('Signed in successfully'), {
        variant: 'success',
      });
      if (onSuccesfullAuthentication) {
        onSuccesfullAuthentication();
      }
      showModal(null, null);
    } catch (error) {
      enqueueSnackbar(t('Sign in failed'), {
        variant: 'error',
      });
      setError(error as Error);
    } finally {
      setProcessing(null);
    }
  };
  const handleGoogleSignIn = async () => {
    try {
      setProcessing('google.com');
      setError(null);
      await googleSignIn();
      enqueueSnackbar(t('Signed in successfully'), {
        variant: 'success',
      });
      if (onSuccesfullAuthentication) {
        onSuccesfullAuthentication();
      }
      showModal(null, null);
    } catch (error) {
      enqueueSnackbar(t('Sign in failed'), {
        variant: 'error',
      });
      setError(error as Error);
    } finally {
      setProcessing(null);
    }
  };
  const hanldeTwitterSignIn = async () => {
    try {
      setProcessing('twitter.com');
      setError(null);
      await twitterSignIn();
      enqueueSnackbar(t('Signed in successfully'), {
        variant: 'success',
      });
      if (onSuccesfullAuthentication) {
        onSuccesfullAuthentication();
      }
      showModal(null, null);
    } catch (error) {
      enqueueSnackbar(t('Sign in failed'), {
        variant: 'error',
      });
      setError(error as Error);
    } finally {
      setProcessing(null);
    }
  };
  const handleSwitchToRegister = (email: string): void => {
    showModal('register');
    setEmail(email);
  };
  const signIn = (
    <>
      <DialogTitle>{t('Sign in with email and password')}</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: 2,
        }}
      >
        <SignInForm
          email={email}
          onClose={closeModal}
          onSignInWithEmailAndPassword={handleSignInWithEmailAndPassword}
          onSwitchToRegister={handleSwitchToRegister}
          onSwitchToForgotPassword={(email) => {
            showModal('forgotPassword');
            setEmail(email);
          }}
          processing={processing}
          errorMessage={
            error ? (
              <AuthenticationErrorViewer error={error} email={email} />
            ) : null
          }
        />
        <Divider>
          <Chip color="secondary" label={t('OR')} />
        </Divider>
        <SignInWithProviders
          processing={processing}
          onFacebookSignIn={handleFacebookSignIn}
          onGoogleSignIn={handleGoogleSignIn}
          onTwitterSignIn={hanldeTwitterSignIn}
        />
      </DialogContent>
    </>
  );

  const forgot = (
    <>
      <DialogTitle>{t('Reset your password')}</DialogTitle>
      <DialogContent>
        <ForgotPasswordForm
          email={email}
          onClose={closeModal}
          onSendPasswordResetEmail={handleSendPasswordResetEmail}
          onSwitchToRegister={handleSwitchToRegister}
          processing={processing}
          errorMessage={
            error ? (
              <AuthenticationErrorViewer error={error} email={email} />
            ) : null
          }
        />
      </DialogContent>
    </>
  );

  const continueAsGuest = (
    <>
      <DialogTitle>{t('Set nickname')}</DialogTitle>
      <DialogContent>
        <ContinueWithNickForm
          closeModal={closeModal}
          signInAnonymously={signInAnonymously}
        />
      </DialogContent>
    </>
  );

  const reauthenticate = (
    <>
      <DialogTitle>{t('Reauthenticate')}</DialogTitle>
      <DialogContent>
        <ReauthenticateFeature />
      </DialogContent>
    </>
  );

  let modal: JSX.Element | null = null;

  switch (authenticationModalVisible) {
    case 'register':
      modal = register;
      break;
    case 'signIn':
      modal = signIn;
      break;
    case 'forgotPassword':
      modal = forgot;
      break;
    case 'continueWithNick':
      modal = continueAsGuest;
      break;
    case 'reauthenticate':
      modal = reauthenticate;
      break;
  }

  if (!modal) return null;

  if (error && error instanceof UserAlreadyRegisteredWithOtherProviderError)
    modal = (
      <>
        <DialogTitle>{t('Combine with existing account')}</DialogTitle>
        <DialogContent>
          <UserAlreadyRegisteredWithOtherMethodError
            email={error.data.email}
            provider={error.data.provider}
            loginMethods={error.data.loginMethods}
          />
        </DialogContent>
      </>
    );

  return (
    <Dialog
      scroll="body"
      open={Boolean(authenticationModalVisible)}
      onClose={closeModal}
      fullWidth
    >
      {modal}
    </Dialog>
  );
}

export default AuthenticationModalFeature;
