import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import HowToReg from '@mui/icons-material/HowToReg';
import Login from '@mui/icons-material/Login';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'next-i18next';
import { Fragment, MouseEvent, useState } from 'react';

/**
 * Props for the LoginAndSignupMenu component.
 */
export interface LoginAndSignupMenuProps {
  /**
   * Function to be called when the user clicks on the sign in button.
   */
  onSignIn: () => void;
  /**
   * Function to be called when the user clicks on the register button.
   */
  onRegister: () => void;
}

/**
 * A component that displays a login and signup menu with buttons for signing in and registering.
 *
 * @param onSignIn - A function to be called when the user clicks the "Login" button.
 * @param onRegister - A function to be called when the user clicks the "Sign Up" button.
 */
export function LoginAndSignupMenu({
  onSignIn: signIn,
  onRegister: register,
}: LoginAndSignupMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
          gap: 1,
        }}
      >
        <Button
          color="inherit"
          variant="outlined"
          data-test="login-button"
          onClick={signIn}
        >
          {t('Login')}
        </Button>

        <Button
          color="inherit"
          variant="outlined"
          data-test="signup-button"
          onClick={register}
        >
          {t('Sign Up')}
        </Button>
      </Box>
      <Box
        data-test="login-and-signup-menu"
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
        }}
      >
        <Tooltip title={t('Login or Signup')}>
          <IconButton
            onClick={handleClick}
            color="inherit"
            size="small"
            // sx={{ ml: 2 }}
            aria-controls={open ? 'login-and-signup-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <AccountCircleRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="login-and-signup-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        keepMounted
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={signIn}>
          <ListItemIcon>
            <Login fontSize="small" />
          </ListItemIcon>
          {t('Login')}
        </MenuItem>

        <Divider />
        <MenuItem onClick={register}>
          <ListItemIcon>
            <HowToReg fontSize="small" />
          </ListItemIcon>
          {t('Signup')}
        </MenuItem>
      </Menu>
    </Fragment>
  );
}

export default LoginAndSignupMenu;
