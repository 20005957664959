import Accordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEnvironment } from '@livekatsomo/web/hooks';
import { JSONTree } from 'react-json-tree';
import { useTranslation } from 'next-i18next';
import NoSsr from '@mui/material/NoSsr';

export interface DebugDetailsProps {
  data?: object | null;
}

export function DebugDetails({
  data,
  sx,
}: DebugDetailsProps & Pick<AccordionProps, 'sx'>) {
  const { development } = useEnvironment();
  const { t } = useTranslation();
  if (!development || process.env.NO_DEBUG_DETAILS) return null;

  return (
    <NoSsr>
      <Accordion sx={sx}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{t('Debug')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ overflow: 'auto' }} component="pre">
            {data ? <JSONTree data={data}></JSONTree> : null}
          </Box>
        </AccordionDetails>
      </Accordion>
    </NoSsr>
  );
}

export default DebugDetails;
