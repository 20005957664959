/**
 * Converts a hue value to its corresponding RGB value.
 * @param p - The first color value.
 * @param q - The second color value.
 * @param t - The third color value.
 * @returns The RGB value corresponding to the given hue.
 */
export function hue2rgb(p: number, q: number, t: number) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}
