import { updateDisplayName } from '@livekatsomo/web/data';
import { useAuthentication } from '@livekatsomo/web/data-hooks';
import { TextField } from '@livekatsomo/web/ui-components/react-hook-form-components';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { User } from 'firebase/auth';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export interface ContinueWithNickFormProps {
  signInAnonymously: (nickName: string) => Promise<User>;
  closeModal: () => void;
}

/**
 * A form component for continuing with a nickname.
 * @param props - The component props.
 * @param props.signInAnonymously - A function to sign in anonymously with a nickname.
 * @param props.closeModal - A function to close the modal.
 * @returns The JSX element for the component.
 */
export function ContinueWithNickForm({
  signInAnonymously,
  closeModal,
}: ContinueWithNickFormProps) {
  const { t } = useTranslation();
  const { user } = useAuthentication();
  const [processing, setProcessing] = useState<boolean>(false);
  const methods = useForm({
    defaultValues: {
      nickName: '',
    },
  });

  /**
   * Handles the form submission.
   * @param data - The form data.
   * @param data.nickName - The nickname.
   * @returns - A promise that resolves when the submission is complete.
   */
  const submit = async (data: { nickName: string }): Promise<void> => {
    setProcessing(true);
    if (user) {
      await updateDisplayName(data.nickName);
    }
    await signInAnonymously(data.nickName);
    setProcessing(false);
    methods.reset();
    closeModal();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <TextField
          margin="normal"
          name="nickName"
          fullWidth
          label="Nick name"
        />
        <DialogActions>
          <LoadingButton type="submit" loading={!!processing}>
            {t('Save')}
          </LoadingButton>
          <Button
            onClick={() => {
              methods.reset();
              closeModal();
            }}
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
}
