import { AssetDoc } from '@livekatsomo/models';

/**
 * Returns the URL of the avatar image for a given asset.
 * If the asset is an SVG or doesn't have download URLs, returns the original URL.
 * Otherwise, returns the URL for the 200x200 PNG download.
 *
 * @param asset - The asset to get the avatar URL for.
 * @returns The URL of the avatar image.
 */
export function getAvatarUrl(
  asset: Pick<AssetDoc, 'downloadUrls' | 'originalUrl' | 'purpose' | 'type'>,
) {
  if (asset.type === 'image/svg+xml' || !asset.downloadUrls) {
    return asset.originalUrl;
  } else {
    return asset.downloadUrls.png200x200;
  }
}
