/**
 * Hashes a string and returns an integer between 0 and the specified maximum value.
 * Uses the FNV-1a algorithm with 32-bit output.
 * @param str The string to hash.
 * @param max The maximum value of the output integer. Defaults to 16777215.
 * @returns An integer between 0 and the specified maximum value.
 */
export function hashInt(str: string, max = 16777215) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash = hash & hash;
  }
  return Math.round((max * Math.abs(hash)) / 2147483648);
}
