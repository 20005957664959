import { Event } from '@livekatsomo/models';
import { isLiveRightNow } from '@livekatsomo/shared/utils';
import { differenceInHours } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useDateFns } from './useDateFns';

/**
 * Returns a string representing the subheader for the given event.
 * If the event is live, returns a string indicating how long ago the event started.
 * If the event is upcoming, returns a string indicating when the event starts.
 * If the event has ended, returns a string indicating how long ago the event ended.
 * If the event has no start date, returns an empty string.
 *
 * @param event - The event to generate the subheader for.
 * @returns A string representing the subheader for the given event.
 */
export function useSubHeader(event: Event) {
  const { t } = useTranslation();
  const { format, formatDistance } = useDateFns();
  if (isLiveRightNow(event)) {
    return t('Started {{timeDistance}}', {
      timeDistance: formatDistance(event.startDate, new Date(), {
        addSuffix: true,
      }),
    });
  }
  const startDifference = differenceInHours(event.startDate, new Date());
  if (0 < startDifference && startDifference < 24) {
    return t('Starts {{timeDistance}}', {
      timeDistace: formatDistance(event.startDate, new Date(), {
        addSuffix: true,
      }),
    });
  }

  if (event.endDate < new Date()) {
    return t('Ended {{timeDistance}}', {
      timeDistance: formatDistance(event.endDate, new Date(), {
        addSuffix: true,
      }),
    });
  }
  return format(event.startDate);
}
