import { useState } from 'react';

/**
 * A hook that provides functionality for managing a list menu.
 * @typeParam Item The type of the items in the list.
 * @returns An object containing the selected item, functions to set the selected item, close the menu, and open the menu.
 */
export function useListMenu<Item>() {
  const [selectedItem, setSelectedItem] = useState<{
    item: Item;
    anchor: HTMLElement;
  } | null>(null);

  const closeMenu = () => {
    setSelectedItem(null);
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, item: Item) => {
    setSelectedItem({ item, anchor: event.currentTarget });
  };

  return {
    selectedItem,
    setSelectedItem,
    closeMenu,
    openMenu,
  };
}
