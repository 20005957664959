import { FileUploadProgess, UploadProgressFunction } from '@livekatsomo/types';
import { useCallback, useState } from 'react';

/**
 * A hook that returns an object with image, uploadProgress and handleUploadFile properties.
 * @param uploadImage - A function that takes a file and an optional setProgress function and uploads the file.
 * @returns An object with image, uploadProgress and handleUploadFile properties.
 */
export function useImageUploader(
  uploadImage: (file: File, setProgress?: UploadProgressFunction) => void,
) {
  const [image, setFile] = useState<{
    preview: string;
    file: File;
  }>();
  const [uploadProgress, setProgress] = useState<FileUploadProgess>({});

  const handleUploadFile = useCallback(
    (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) {
        return;
      }
      const file = acceptedFiles[0];
      setFile({
        preview: URL.createObjectURL(file),
        file,
      });
      return uploadImage(file, setProgress);
    },
    [uploadImage],
  );

  return {
    image,
    uploadProgress,
    handleUploadFile,
  };
}
