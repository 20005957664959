import { AssetDoc } from '@livekatsomo/models';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import React from 'react';
import { getAvatarUrl } from '@livekatsomo/shared/utils';

/**
 * A component that displays an avatar image for an asset.
 * @param asset An object containing information about the asset, including download URLs, original URL, purpose, and type.
 * @param url The URL of the avatar image.
 * @param props Additional props to pass to the Avatar component.
 * @returns An Avatar component displaying the asset avatar image.
 */
export const AssetAvatar = React.forwardRef<
  HTMLImageElement,
  {
    asset?: Pick<AssetDoc, 'downloadUrls' | 'originalUrl' | 'purpose' | 'type'>;
    url?: string | null;
  } & AvatarProps
>(({ asset, url, ...props }, ref) => {
  if (asset) {
    return (
      <Avatar
        ref={ref}
        src={getAvatarUrl(asset)}
        {...props}
        sx={[
          asset.purpose?.includes('logo')
            ? {
                '& .MuiAvatar-img': {
                  objectFit: 'contain',
                },
              }
            : {},
        ]}
      />
    );
  }
  return <Avatar ref={ref} src={url || undefined} {...props} />;
});
