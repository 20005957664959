import { User } from '@livekatsomo/models';
import { AccountMenu } from '@livekatsomo/web/ui-components/account-menu';
import { LoginAndSignupMenu } from '../LoginAndSignupMenu/LoginAndSignupMenu';

/**
 * Props for the UserMenu component.
 */
export interface UserMenuProps {
  user: User | null;
  onLogout?: () => void;
  onSignIn: () => void;
  onRegister: () => void;
}

/**
 * Renders a user menu component based on the user's authentication status.
 * If the user is authenticated, it renders an account menu with the user's information and a logout button.
 * If the user is not authenticated, it renders a login and signup menu with buttons to sign in or register.
 * @param props - The props object containing the user's information and callback functions for signing in, registering, and logging out.
 * @returns  - The rendered user menu component.
 */
export function UserMenu({
  user,
  onLogout,
  onSignIn,
  onRegister,
}: UserMenuProps) {
  return user && !user.isAnonymous ? (
    <AccountMenu {...user} onLogout={onLogout} />
  ) : (
    <LoginAndSignupMenu onSignIn={onSignIn} onRegister={onRegister} />
  );
}

export default UserMenu;
