import { useAuthentication } from '@livekatsomo/web/data-hooks';
import { AdminMenuItems } from '@livekatsomo/web/ui-components/layout';

/**
 * Renders the admin menu items if the user has the 'admin' role.
 * @returns The rendered component.
 */
export function AdminMenuFeature() {
  const { user } = useAuthentication();

  if (!user) return null;

  if (user.roles?.includes('admin')) {
    return <AdminMenuItems />;
  } else {
    return null;
  }
}
