import { AuthenticationModalFeature } from '@livekatsomo/web/features/authentication-modal';
import { useAuthentication } from '@livekatsomo/web/data-hooks';
import { UserMenu } from '@livekatsomo/web/ui-components/user-menu';

/**
 * Renders the user menu feature, which displays the user's information and allows them to sign in, register, or log out.
 * @returns A React component that renders the user menu feature.
 */
export function UserMenuFeature() {
  const { user, logout, showModal } = useAuthentication();

  return (
    <>
      <UserMenu
        user={user}
        onLogout={logout}
        onSignIn={() => showModal('signIn')}
        onRegister={() => showModal('register')}
      />
      <AuthenticationModalFeature />
    </>
  );
}
