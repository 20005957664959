import { useContext } from 'react';

import { DateLike, DateFnsContext } from '@livekatsomo/web/contexts';
import {
  Duration,
  FormatDurationOptions,
  format,
  formatDistance,
  formatDuration,
} from 'date-fns';

/**
 * Provides utility functions for formatting dates using the date-fns library.
 * @returns An object containing the `format`, `formatDistance`, and `formatDuration` functions.
 * @throws An error if used outside of a `DateFnsProvider`.
 */
export function useDateFns() {
  const context = useContext(DateFnsContext);
  if (context === undefined) {
    throw new Error('useDateFns must be used within DateFnsProvider');
  }

  return {
    format: (date: DateLike, formatStr = 'PP') => {
      const { locale } = context;
      return format(date, formatStr, {
        locale,
      });
    },
    // Will show "in 5 minutes" or "5 minutes ago" depending on the date
    formatDistance: (
      date: DateLike,
      baseDate = new Date(),
      options?: {
        includeSeconds?: boolean | undefined;
        addSuffix?: boolean | undefined;
      },
    ) => {
      const { locale } = context;
      return formatDistance(date, baseDate, {
        locale,
        ...options,
      });
    },
    formatDuration: (duration: Duration, options?: FormatDurationOptions) => {
      const { locale } = context;
      return formatDuration(duration, { locale, ...options });
    },
  };
}
