import Facebook from '@mui/icons-material/Facebook';
import Google from '@mui/icons-material/Google';
import Twitter from '@mui/icons-material/Twitter';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { PasswordReauthenticateForm } from '../PasswordReauthenticateForm';
import { AuthenticationErrorViewer } from '@livekatsomo/web/features/authentication-error-viewer';
import { useAuthentication } from '@livekatsomo/web/data-hooks';

/**
 * Renders a reauthentication feature that allows the user to reauthenticate with their
 * account using various providers such as Google, Facebook, and Twitter.
 *
 * @returns The reauthentication feature component.
 */
export function ReauthenticateFeature() {
  const { t } = useTranslation();
  const {
    user,
    error,
    processing,
    providers,
    reauthenticateWithCredential,
    reauthenticateWithPopup,
  } = useAuthentication();
  const nodes: [string, ReactNode][] = [];

  const email = user?.email;

  if (!email) {
    return null;
  }

  if (providers?.includes('password')) {
    nodes.push([
      'password',
      <PasswordReauthenticateForm
        submit={(password) => reauthenticateWithCredential(email, password)}
        errorMessage={
          error ? <AuthenticationErrorViewer error={error} /> : null
        }
      />,
    ]);
  }
  if (providers?.includes('google.com')) {
    nodes.push([
      'google.com',
      <LoadingButton
        fullWidth
        variant="outlined"
        loading={processing === 'google.com'}
        loadingPosition="start"
        startIcon={<Google />}
        disabled={!!processing}
        onClick={() => reauthenticateWithPopup('google.com')}
      >
        {t('Reauthenticate with Google')}
      </LoadingButton>,
    ]);
  }
  if (providers?.includes('facebook.com')) {
    nodes.push([
      'facebook.com',
      <LoadingButton
        fullWidth
        variant="outlined"
        loading={processing === 'facebook.com'}
        loadingPosition="start"
        startIcon={<Facebook />}
        disabled={!!processing}
        onClick={() => reauthenticateWithPopup('facebook.com')}
      >
        {t('Reauthenticate with Facebook')}
      </LoadingButton>,
    ]);
  }
  if (providers?.includes('twitter.com')) {
    nodes.push([
      'twitter.com',
      <LoadingButton
        fullWidth
        variant="outlined"
        loading={processing === 'twitter.com'}
        loadingPosition="start"
        startIcon={<Twitter />}
        disabled={!!processing}
        onClick={() => reauthenticateWithPopup('twitter.com')}
      >
        {t('Reauthenticate with Twitter')}
      </LoadingButton>,
    ]);
  }

  return providers ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {nodes.map(([key, node]) => (
        <Box key={key}>{node}</Box>
      ))}
    </Box>
  ) : null;
}
