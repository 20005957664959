import { TextField } from '@livekatsomo/web/ui-components/react-hook-form-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'next-i18next';
import { FormProvider, useForm } from 'react-hook-form';

export interface PasswordReauthenticateFormProps {
  submit: (password: string) => void;
  errorMessage?: React.ReactNode;
}

/**
 * PasswordReauthenticateForm component.
 * @param props - Component props.
 * @param props.submit - Function to be called when the form is submitted.
 * @param props.errorMessage - Error message to be displayed.
 * @returns PasswordReauthenticateForm component.
 */
export function PasswordReauthenticateForm({
  submit,
  errorMessage,
}: PasswordReauthenticateFormProps) {
  const { t } = useTranslation();
  const methods = useForm({
    defaultValues: {
      password: '',
    },
  });
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit((data) => submit(data.password))}>
        <TextField
          margin="normal"
          name="password"
          type="password"
          fullWidth
          label="Password"
        />
        <Box sx={{ color: (theme) => theme.palette.error.main }}>
          {errorMessage}
        </Box>
        <DialogActions>
          <Button type="submit">{t('Submit')}</Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
}
